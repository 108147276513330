<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row>
                        <b-col cols="12">
                            <!-- media -->
                            <b-media no-body>
                                <b-media-body class="mt-75 ml-75">
                                    <b-row>
                                        <b-col cols="12" sm="4">
                                            <!-- upload button -->

                                            <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                                            <!--/ upload button -->
                                        </b-col>
                                    </b-row>
                                    <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                                </b-media-body>
                            </b-media>
                            <!--/ media -->
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="4">
                            <validation-provider #default="validationContext" name="name" rules="required">
                                <b-form-group label="Name" label-for="name">
                                    <b-form-input id="name" v-model="form.name" :state="getValidationState(validationContext)" trim />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Arabic Name -->
                        <b-col cols="12" sm="6" md="4">
                            <validation-provider name="genre">
                                <b-form-group label="Genre Name" label-for="genre-name">
                                    <v-select v-model="form.genre_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="genres" label="name_ar" :clearable="false" input-id="genre" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Slug -->
                        <b-col cols="12" sm="6" md="4">
                            <validation-provider name="slug">
                                <b-form-group label="Slug" label-for="slug">
                                    <b-form-input id="slug-name" v-model="form.slug" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Description -->
                        <b-col cols="12">
                            <validation-provider name="Description">
                                <b-form-group label="Description" label-for="Description">
                                    <b-form-textarea v-model="form.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.value" input-id="Description" rows="4" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Form Actions -->
                        <b-col cols="12">
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                    {{ moodId ? "update" : "add" }}
                                </b-button>
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
        <div class="text-center">
            <AddSongModal />
        </div>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import usemoodList from "./useMoodList";
import useGenre from "../genre/useGenre";
import useFile from "../file/useFile";

import AddSongModal from "./AddSong.vue";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        AddSongModal,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {
            name: "",
            genre_id: "",
            slug: "",
            file_batch_id: RANDOM_TOKEN,
        };

        const moodId = ref(null);

        const form = ref(JSON.parse(JSON.stringify(blankForm)));

        if (router.currentRoute.params.id) {
            moodId.value = router.currentRoute.params.id;
        }

        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "main_image", "playlist");
        const { storePlaylist, updatePlaylist, showPlaylist } = usemoodList();
        const { fetchGenre, genres } = useGenre();
        // Register module

        fetchGenre();

        const onSubmit = async () => {
            if (fileForm.value.file) {
                await storeFile();
            }
            if (moodId.value) {
                updatePlaylist(moodId.value, form.value).then((res) => {
                    router.push({ name: "apps-moods-list" });
                });
            } else {
                storePlaylist(form.value).then((res) => {
                    router.push({ name: "apps-moods-list" });
                });
            }
        };

        // Reset Validation
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

        // fill Form When edit page
        const showData = async () => {
            const response = await showPlaylist(moodId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
            } else {
                form.value = undefined;
            }
        };

        if (moodId.value) {
            showData();
        }

        return {
            form,
            fileForm,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            moodId,
            genres,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
